<template>
  <div>
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3">
        <b-col lg="1">
          <b-form-group label="Código">
            <b-form-input v-model="filter.code" v-on:keyup.enter="handleFilter"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Cliente">
            <b-form-input v-model="filter.advertiser" v-on:keyup.enter="handleFilter"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <b-form-group label="Tipo de produto">
            <b-form-select v-model="filter.product">
              <b-form-select-option :value="null">Todos</b-form-select-option>
              <b-form-select-option value="highlight">Destaque</b-form-select-option>
              <b-form-select-option value="super_highlight">Super Destaque</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <b-form-group label="Negócio">
            <b-form-select v-model="filter.business">
              <b-form-select-option :value="null">Todos</b-form-select-option>
              <b-form-select-option value="Venda">Venda</b-form-select-option>
              <b-form-select-option value="Locação">Locação</b-form-select-option>
              <b-form-select-option value="Temporada">Temporada</b-form-select-option>
              <b-form-select-option value="Lançamento">Lançamentos</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="2">
          <b-form-group label="Forma do relatório">
            <b-form-select v-model="type">
              <b-form-select-option value="analytic">Analítico</b-form-select-option>
              <b-form-select-option value="synthetic">Sintético</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col lg="12" class="py-3">
        <b-button @click="handleFilter" variant="primary" class="mr-2" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <i class="fas fa-search" v-if="!loading"></i> Pesquisar
        </b-button>
        <b-button variant="danger" class="mr-2" @click="handleClearFilter">
          <i class="fas fa-broom"></i> Limpar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <b-form-checkbox
            v-if="type === 'synthetic'"
            v-model="onlyDivergent"
            :value="true"
            :unchecked-value="false"
        >
          Somente dados divergentes
        </b-form-checkbox>
        <b-table
            responsive
            class="mt-3"
            :items="onlyDivergent ? itemsDivergent : items"
            :busy="loading"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Destaque encontrado</h4>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #head(contract)="data">
            {{ data.label }} ({{totalContract}})
          </template>

          <template #head(total)="data">
            {{ data.label }} ({{ totalUsed }})
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>

import HighlightService from '@/services/highlights/HighlightService';

export default {
  data() {
    return {
      fields: HighlightService.getFieldsTable(),
      items: [],
      filters: false,
      type: 'analytic',
      onlyDivergent: false,
      filter: {
        product: null,
        advertiser: null,
        code: null,
        business: null
      },
      loading: false,
    };
  },
  computed: {
    itemsDivergent() {
      return this.items.filter((item) => {
        return item.total < item.contract;
      });
    },
    totalContract() {
      if(this.loading) {
        return 0;
      }

      const items = this.onlyDivergent ? this.itemsDivergent : this.items
      return items.reduce((acc, obj) => { return acc + obj.contract; }, 0);
    },
    totalUsed() {
      if(this.loading) {
        return 0;
      }

      const items = this.onlyDivergent ? this.itemsDivergent : this.items
      return items.reduce((acc, obj) => { return acc + obj.total; }, 0);
    }
  },
  async mounted() {
    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      this.$router.push({name: 'login'});
    }
    await this.getAll();
  },
  methods: {
    async getAll(filter) {
      this.loading = true;
      await HighlightService.getList(filter).then((response) => {
        this.items = response.data.map((item) => {
          return {
            advertiser: item.advertiser,
            highlight_type: item.highlight_type === "highlight" ? 'Destaque' : 'Super Destaque',
            reference: item.reference,
            business_type: item.business_type,
            subsee_reference: item.subsee_reference,
            internal_code: item.internal_code,
            has_map : item.has_map,
            total_photos: item.total_photos
          }
        })
      });
      this.loading = false;
    },
    async getTotal(filter) {
      this.loading = true;
      await HighlightService.getTotal(filter).then((response) => {
        this.items = response.data.map((item) => {
          return {
            advertiser: item.advertiser,
            highlight_type: item.highlight_type === "highlight" ? 'Destaque' : 'Super Destaque',
            total: item.total,
            contract: item.contract,
            business_type: item.business_type,
            subsee_reference: item.subsee_reference
          }
        })
      });
      this.loading = false;
    },

    handleFilter() {
      this.onlyDivergent = false;
      if(this.type === 'analytic') {
        this.fields = HighlightService.getFieldsTable();
        this.getAll(this.filter);
      } else {
        this.fields = HighlightService.getFieldsTableSynthetic();
        this.getTotal(this.filter)
      }
    },
    handleClearFilter() {
      this.filter = {};
      this.getAll();
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.hide {
  display: none;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
}
</style>
